// ServicesItems.js
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import '../components/style/servicesitems.css'

function ServicesItems() {
    const { id } = useParams();

    const [servicesData, setServicesData] = useState([
        {
            name: 'تصاميم هندسية',
            id: 1,
            img:"/images/stf26.jpg",
            bio:".تركز على تطوير الرسومات والمخططات الفنية والتقنية التي تصف التفاصيل الدقيقة لتنفيذ المشاريع الهندسية، بما في ذلك التصميم الإنشائي والكهربائي والميكانيكي وغيرها",
        },
        {
            name: 'تصاميم معمارية وانشائية',
            id: 2,
            img:"/images/stf117.jpg",

            bio:"التصميم المعماري يركز على تطوير تصورات جمالية ووظيفية للمباني والمساحات الخارجية، بينما التصميم الإنشائي يركز على تطوير التصاميم الهندسية للهياكل والأساسات التي تضمن المتانة والاستقرار الهندسي للمشاريع الإنشائية. ",
        },
        {
            name: 'تصاميم داخلية',
            id: 3,
            img:"/images/stf121.jpg",
            bio:"تهدف إلى تحسين وتنظيم المساحات الداخلية للمباني والمنشآت، من خلال توظيف عناصر التصميم مثل الألوان والمواد والأثاث، لإنشاء بيئة مريحة وجذابة ووظيفية تلبي احتياجات السكان أو المستخدمين.",
        },
        {
            name: 'تنفيذ',
            id: 4,
            img:"/images/stf22.jpg",
            bio:" تشمل تنفيذ المشروع وتنفيذ الخطط والتصاميم الهندسية بدقة وفعالية، بالاعتماد على فريق مؤهل من المهندسين والفنيين لضمان جودة العمل والالتزام بالمواعيد المحددة.",
        },
        {
            name: 'اشراف هندسي',
            id: 5,
            img:"/images/stf111.jpg",
            bio:"تضمن مراقبة وتنسيق تنفيذ المشاريع الهندسية لضمان الامتثال للمواصفات والمعايير الفنية والجودة، مما يضمن تنفيذ المشروع بكفاءة وفعالية."

        },
        {
            name: 'استشارات هندسية',
            id: 6,
            img:"/images/stf112.jpg",
            bio:"الاستشارات الهندسية تقدم خبرات ونصائح مهنية في مجالات الهندسة المختلفة مثل الإنشاءات، الكهرباء، الطاقة، وغيرها، لمساعدة العملاء في اتخاذ قرارات مستنيرة وتحقيق أهدافهم الهندسية بكفاءة وجودة عالية. ",
        },
        {
            name: 'ترميم',
            id: 7,
            img:"/images/stf115.jpg",
            bio:"خدمة الترميم تهدف إلى استعادة وتجديد المباني والمنشآت القائمة، سواء كانت تالفة أو قديمة، من خلال تطبيق تقنيات متقدمة ومواد عالية الجودة. ",
        },
        {
            name: 'حساب كميات',
            id: 8,
            img:"/images/stf118.jpg",
            bio:"هو عملية تحديد الكميات المطلوبة من المواد والموارد لتنفيذ مشروع معين، ويتم ذلك من خلال تحليل الرسومات والمواصفات الفنية، مما يساعد في التخطيط الدقيق للموارد وضمان تنفيذ المشروع بكفاءة وفعالية.",
        },
        {
            name: 'اعمال المساحة',
            id: 9,
            img:"/images/stf113.jpg",
            bio:"أعمال المساحة تتضمن قياس وتحديد المسافات والمساحات في المواقع الهندسية باستخدام أدوات متقدمة، مما يساعد في تحديد المواقع بدقة وتخطيط المشاريع بفعالية. ",
        },
        {
            name: 'تاسيسات كهربائية',
            id: 10,
            img:"/images/stf116.jpg",
            bio:" تخطيط وتركيب الأنظمة الكهربائية للمباني والمنشآت، مع الانتباه إلى السلامة والكفاءة في الاستخدام.",
        },
        {
            name: 'تاسيسات صحية',
            id: 11,
            img:"/images/stf27.jpg",
            bio:"فريقنا مختص بالبناء والتصميم العصري عبر توفير احدث الطرق وعلى يد اكفاء الايادي هدفنا هو ارضائكم  وسعادتكم بالاختيار  ",
        },
        {
            name: 'طلب فني',
            id: 12,
            img:"/images/stf114.jpg",
            bio:"فريقنا مختص بالبناء والتصميم العصري عبر توفير احدث الطرق وعلى يد اكفاء الايادي هدفنا هو ارضائكم  وسعادتكم بالاختيار  ",
        },
    ])
    return (
        <div className='services-items'>
            
            {servicesData.map((service) => (
        <div className='serve-items' key={service.id}>
            <img src={service.img} alt={service.name} className="cardsimge" />
                        <h2 className="service-namee">{service.name}</h2>
                    <p className='service-bio'>{service.bio}</p>
                    <Link to="/contact">
                        <button className='click'>اطلب الأن</button>
                        </Link>
        </div>
             
          ))}
        </div>
      );
    
}

export default ServicesItems;
