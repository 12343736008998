import React from 'react'
import ContactInfoItems from './ContactInfoItems'
import { FaPhone } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import ContactForm from './ContactForm';
import { FaWhatsapp } from 'react-icons/fa';



function ContactSection() {
  return (
    <div>
      <div className="container-contact">
      {/* <img src='images/sli2.jpeg' alt='img'/> */}
         <div className="contant--overlay"> 

              <h1 className='contact-h'> تواصل معنا</h1>
              <div className="contactSection--wrapper">
            <div className="left">
              
               
              {/* <h1 className='text-contact'>تواصل  سريع</h1> */}
            <a href="tel:+9647812039439" className='whats-content'>
  <ContactInfoItems
    icon={<FaPhone />}
    text='اتصل بنا'
  />
</a>




              <a href='mailto:office@stffordesign.com' className='mail-contact'>
              
                      <ContactInfoItems
              icon={<MdOutlineEmail />
                
                  } text='تواصل معنا عبر البريد الألكتروني' />
                
                </a>
              
              <a href="https://api.whatsapp.com/send?phone=9647812039439" className='whats-content'>
               <ContactInfoItems
                      icon={<FaWhatsapp />
                    } text='تواصل معنا عبر الواتساب'
                
                />
                </a>
              

                <a href="https://www.google.com/maps/place/33%C2%B019'21.5%22N+44%C2%B026'58.1%22E/@33.3228343,44.4497261,20.51z/data=!4m4!3m3!8m2!3d33.3226298!4d44.4494679?hl=ar&entry=ttu"
                      target='-blank' rel='noreferrer' className='map-contact'>
                <ContactInfoItems text=' بغداد , شارع الربيعي , مقابل ماكسي مول'  />
                </a>













                  </div>
                  <div className="right">
                      <ContactForm/>
                  </div>
        </div>
        </div>
      </div>
      </div>
  )
}

export default ContactSection
