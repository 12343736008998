import React from 'react'
import '../components/style/contactinfoitems.css'
import PText from './PText'



function Map() {
  return (
    <div>
          <div className="container-map">
              <div className="map--card">
                  <h3 className='map-card-heading'> هذا موقعنا </h3>
                  <PText>شارع الربيعي ,  بناية بابل</PText>
                  <a href="https://www.google.com/maps/place/33%C2%B019'21.5%22N+44%C2%B026'58.1%22E/@33.3228343,44.4497261,20.51z/data=!4m4!3m3!8m2!3d33.3226298!4d44.4494679?hl=ar&entry=ttu"
                      target='-blank' rel='noreferrer' className='map-card-link'>
                      افتح الموقع في خرائط كوكل</a>
              </div>
          </div>
      </div>
  )
}

export default Map
