import React from 'react'
import { MdPlace } from "react-icons/md";
import '../components/style/contactinfoitems.css'
import PText from './PText';



export default function ContactInfoItems({
  icon = <MdPlace></MdPlace>,
  text = 'this is an info',
  
  
  
}) {
  return (
    <div className='div-style'>
      <div className="icon">{icon}</div>
      <div className="info-contact">
        
        <PText>{text}</PText>

      </div>
    </div>
  )
}

