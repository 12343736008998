import "./App.css";
import React from 'react'



import Layout from './layout/Layout'
import { Routes,Route } from 'react-router-dom'
import HomePage from './pages/HomePage';
import ServicesPage from "./pages/ServicesPage";

import Contact from "./pages/Contact";
import Projects from "./pages/Projects";
import AboutPage from "./pages/AboutPage";

function App() {
    return (
        <>
            <Layout>
           
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/services" element={<ServicesPage />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/about" element={<AboutPage />} />




                    



               

                </Routes>
            </Layout>
        
        </>
    )
}

export default App
