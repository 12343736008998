import React from 'react'
import ContactSection from '../components/ContactSection'
import Map from '../components/Map'


function Contact() {
  return (
    <div>
      < ContactSection />
      <Map/>
    </div>
  )
}

export default Contact
