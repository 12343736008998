import React from 'react';
import '../components/style/aboutstf.css';
import { Link } from 'react-router-dom';

function About() {
  return (
    <div className='continer-about'>
      <div className='about-img'>
        <img
          src='images/stff6.jpg'
          alt='صورة عن STF'
        />
        <div className='about-imge'>
          <img src='images/stff5.jpg' alt='صورة عن STF' />
        </div>
      </div>
      <div className='about-overlay'>
        <div className='about-text'>
        نحن نوفر مجموعة شاملة من الخدمات في مجالات البناء والتصميم، بما في ذلك التصميم المعماري، والهندسة الإنشائية، والترميم، وخدمات هندسية أخرى. يقدم فريقنا المتخصص حلاً مبتكرًا وفعالًا لكل مشروع، بغض النظر عن حجمه أو تعقيده. تقديم الخدمة الممتازة وتحقيق رضا العملاء هو هدفنا الأسمى. لذا، نحن نعمل عن كثب معكم، نستمع إلى احتياجاتكم ونفهم تطلعاتكم، لنقدم لكم الحلول المخصصة التي تلبي تمامًا ما تبحثون عنه.

<div className='about-buttons'>
            <Link to="/contact"><button> اتصل بنا</button>
            </Link>
            <Link to="/about"><button>  من نحن</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
