import React from 'react';
import './style/hero.css';
import video from '../assest/sttf.mp4';

const Hero = () => {
  return (
    <div className="homepage">
      <section className='hero'>
        <video src={video} muted autoPlay loop type="video/mp4"></video>
        {/* <div className='overlay'>
          <div className='text-hero'>
            <h3><span className='span-hero'> STF مكتب </span> للتصميم الهندسي والبناء العمراني</h3>
          </div>
        </div> */}
      </section>
    </div>
  );
};

export default Hero;
