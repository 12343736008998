import React, { useState } from 'react'
import '../components/style/services.css'
import { Link } from 'react-router-dom';

function Services() {

    const [servicesData, setServicesData] = useState([
        {
            name: 'تصاميم هندسية',
            id: 1,
            img:"/images/stf26.jpg",
        },
        {
            name: 'تصاميم معمارية وانشائية',
            id: 2,
            img:"/images/stf117.jpg",
            
        },
        {
            name: 'تصاميم داخلية',
            id: 3,
            img:"/images/stf121.jpg",
            
        },
        {
            name: 'تنفيذ',
            id: 4,
            img:"/images/stf22.jpg",
            
        },
        {
            name: 'اشراف هندسي',
            id: 5,
            img:"/images/stf111.jpg",
            
        },
        {
            name: 'استشارات هندسية',
            id: 6,
            img:"/images/stf112.jpg",
            
        },
        {
            name: 'ترميم',
            id: 7,
            img:"/images/stf115.jpg",
            
        },
        {
            name: 'حساب كميات',
            id: 8,
            img:"/images/stf118.jpg",
            
        },
        {
            name: 'اعمال المساحة',
            id: 9,
            img:"/images/stf113.jpg",
            
        },
        {
            name: 'تاسيسات كهربائية',
            id: 10,
            img:"/images/stf116.jpg",
            
        },
        {
            name: 'تاسيسات صحية',
            id: 11,
            img:"/images/stf122.jpg",
            
        },
        {
            name: 'طلب فني',
            id: 12,
            img:"/images/stf114.jpg",
            
        },
    ])
    return (
        <div className='services-data'>
            
            {servicesData.map((service) => (
                 <Link to={"/services"} className='servic-link' key={service.name}>
        <div className='serve' key={service.id}>
            <img src={service.img} alt={service.name} className="cardsimg" />
            <h2 className="service-name">{service.name}</h2>
        </div>
    </Link>
             
          ))}
        </div>
      );
    }
    
    export default Services;