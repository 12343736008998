import React from 'react'
import Gallary from '../components/Gallary'

function Projects() {
  return (
      <div>
          <h1 className='projects'>من مشاريعنا</h1>
      <Gallary/>
    </div>
  )
}

export default Projects
