import React from 'react'
import AboutUs from '../components/AboutUs'


function AboutPage() {
  return (
    <div>
      <AboutUs/>
    </div>
  )
}

export default AboutPage
