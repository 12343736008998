import React from 'react';
import '../components/style/footer.css'
import logo from '../assest/images/logo.png'
import { Link } from 'react-router-dom';
import { FaFacebookSquare, FaInstagramSquare, FaTiktok,  } from "react-icons/fa";



const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="logo-section">
                <Link to="/"><img src={logo} alt="Logo" width={100} height={60}  /></Link>
                </div>
                <div className="contact-section">
                    <h3> تواصل معنا</h3>
                    <p>Phone: +964 772 636 6539</p>
                    <p>Phone: +964 781 203 9439</p>
                    <p>Email: office@stffordesign.com</p>
                    <a href='https://www.tiktok.com/@stffordesign' target='__thapa'>
                <FaTiktok className='tiktok'/>
              </a> <a href='https://www.facebook.com/search/top?q=%D9%85%D9%86%20%D8%A7%D9%84%D8%A8%D8%AF%D8%A7%D9%8A%D8%A9%20%D8%AD%D8%AA%D9%89%20%D8%A7%D9%84%D9%86%D9%87%D8%A7%D9%8A%D8%A9%20%D8%AE%D8%A8%D8%B1%D8%A7%D8%A1%20%D8%A7%D9%84%D9%87%D9%86%D8%AF%D8%B3%D8%A9%20%D9%85%D8%B9%D9%83%D9%85%20stf' target='__thapa'>
                <FaFacebookSquare className='facebook'/>
                    </a>
                    <a href='https://www.instagram.com/stf.for.design/?igsh=MXQwM2ZnZHN5MjRjbw%3D%3D' target='__thapa'>
                <FaInstagramSquare className='instegram'/>
              </a>
                </div>
                <div className="social-section">
                    <h3 className='yt-section'> كن معنا وشاهدنا</h3><a href='https://www.youtube.com/@stf.3488'>
                    <img
                            src='/images/yt.png' alt='صورة عن yt' width={100} height={100} />
                        </a>
                  
                           </div>
                <div className="map-section">
                <h3>عنوان المكتب  </h3><a href="https://www.google.com/maps/place/33%C2%B019'21.5%22N+44%C2%B026'58.1%22E/@33.3228343,44.4497261,20.51z/data=!4m4!3m3!8m2!3d33.3226298!4d44.4494679?hl=ar&entry=ttu" target='_blank' rel='noreferrer' className='map-card-link'>
                
                <img
                        src='/images/map.png' alt='صورة عن STF' width={100} height={100}/></a>

                  
                   
                </div>
            </div>
            <hr class="divider">
                
            </hr>
            <div class="copyright">
          
            Smart Spark IQ 2024.  جميع الحقوق محفوظة لدى مكتب 
        &copy; 
    </div>
        </footer>
    );
};

export default Footer;
