// AboutUs.js

import React from 'react';
import { motion } from 'framer-motion';

import '../components/style/about.css';

function AboutUs() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1 } },
  };

  return (
    <motion.div
      className="about--container"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div className="about--one">
        <motion.div className="about--img">
          <img src='images/stff44.jpg' alt='Construction Engineers' />
        </motion.div>
        <motion.div className="about--p">
        <h1 className='h-about'> تعرف على مكتب STF : </h1>

         <p>"نحن في مكتب (STF) نفتخر بتقديم خدمات استشارية هندسية متخصصة ومبتكرة لعملائنا. نمتلك فريقًا مؤهلاً من المهندسين والمستشارين الذين يجمعون بين الخبرة الواسعة والتفاني في تقديم الحلول المخصصة لمشاريعكم. نسعى جاهدين لتحقيق رؤى عملائنا وتحقيق أعلى مستويات الجودة والاحترافية في كل تفاصيل العمل. اعتمادًا على التكنولوجيا الحديثة والممارسات الهندسية المتقدمة، نضمن تنفيذ مشاريعكم بكفاءة ودقة تامة، مما يسهم في نجاحكم ونمو أعمالكم بشكل مستدام."

</p>
        </motion.div>
      </motion.div>

      <motion.div className="founder--container">
        <motion.div className="founder--img">
          <img src='images/alhur.jpg' alt='Founder' />
        </motion.div>
        <motion.div className="founder--description">
          <h className="alhure">تعرف على مدير المكتب ومؤسسه  <span className='eng-alhure'>المهندس الحر الصافي</span></h>
          <p className='alhurr'>مهندس الحر  خريج الهندسة المدنية فرع ادارة المشاريع ولديه خبرة اكثر من عشر سنوات ببناء المنازل فضلا عن التصاميم الهندسية العصرية وبطرق مبتكرة وكذلك خبرة في التصاميم  الداخلية والمزيد من الخدمات الاخرى.</p>
        </motion.div>
      </motion.div>

      {/* <motion.div className="stats--container">
        <motion.div className="project-count">
          <h2>عدد المشاريع المنجزة</h2>
          <p>55</p> 
        </motion.div>
        <motion.div className="certificate-count">
          <h2>عدد الشهادات </h2>
          <p>45</p>
        </motion.div>
        <motion.div className="client-count">
          <h2>عدد الزبائن</h2>
          <p>100</p>
        </motion.div>
      </motion.div> */}
    </motion.div>
  );
}

export default AboutUs;
