import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../components/style/works.css'
import 'swiper/swiper-bundle.css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import 'swiper/css';
import { FreeMode, Pagination } from 'swiper/modules';


const Works = () => {
    const slides = [
      {
        id: 1,
        image: '/images/stff1.jpg',
      
      },
      {
        id: 2,
        image: '/images/stff5.jpg',
       
        },
        {
            id: 3,
            image: '/images/stff8.jpg',
           
      },
      {
        id: 4,
        image: '/images/stff6.jpg',
       
      },
      {
        id: 5,
        image: '/images/stff9.jpg',
       
      },
      {
        id: 6,
        image: '/images/stf1111.jpg',
       
      },
      {
        id: 7,
        image: '/images/stf1101.jpg',
       
      },
      {
        id: 8,
        image: '/images/stff4.jpg',
       
      },
     
      // ... المزيد من الكروت
    ];
  
    return (
      <Swiper 
      slidesPerView={3}
      spaceBetween={15}
      freeMode={true}
      pagination={{
        clickable: true,
       
    }}
    modules={[FreeMode, Pagination]}
    className="mySwiper"
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.id}>
            <div className="card">
              <img src={slide.image} alt={slide.id} />
              
             
             
            </div>
            
          
          </SwiperSlide>
          
        ))}
         
      </Swiper>
      
  );
  
};
  
  
  export default Works;