import React, { useState } from 'react';
import { FaFacebookSquare, FaInstagramSquare, FaYoutubeSquare, FaTiktok } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import logo from '../assest/images/logo.png';
import '../components/style/header.css';

function Header() {
  const [showMediaIcons, setShowMediaIcons] = useState(false);

  const closeMenu = () => {
    setShowMediaIcons(false);
  };

  return (
    <>
      <nav className="main-nav">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo" width={150} height={120} />
          </Link>
        </div>

        <div className={showMediaIcons ? 'menu-link mobile-menu-link' : 'menu-link'}>
          <ul>
            {/* <li>
              <a href="#" onClick={closeMenu}>
                اهم الأخبار
              </a>
            </li> */}
            <li>
              <Link to="/about" onClick={closeMenu}>
                من نحن
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={closeMenu}>
                تواصل معنا
              </Link>
            </li>
            <li>
              <Link to="/projects" onClick={closeMenu}>
                مشاريعنا
              </Link>
            </li>
            <li>
              <Link to="/services" onClick={closeMenu}>
                خدماتنا
              </Link>
            </li>
            <li>
              <Link to="/" onClick={closeMenu}>
                الرئيسية
              </Link>
            </li>
          </ul>
        </div>

        <div className="social-media">
          <ul className="social-media-desctop">
            <li>
              <a href="https://www.facebook.com/search/top?q=%D9%85%D9%86%20%D8%A7%D9%84%D8%A8%D8%AF%D8%A7%D9%8A%D8%A9%20%D8%AD%D8%AA%D9%89%20%D8%A7%D9%84%D9%86%D9%87%D8%A7%D9%8A%D8%A9%20%D8%AE%D8%A8%D8%B1%D8%A7%D8%A1%20%D8%A7%D9%84%D9%87%D9%86%D8%AF%D8%B3%D8%A9%20%D9%85%D8%B9%D9%83%D9%85%20stf" target="__thapa">
                <FaFacebookSquare className="facebook" />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/@stf.3488" target="__thapa">
                <FaYoutubeSquare className="youtube" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/stf.for.design/?igsh=MXQwM2ZnZHN5MjRjbw%3D%3D" target="__thapa">
                <FaInstagramSquare className="instegram" />
              </a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@stffordesign" target="__thapa">
                < FaTiktok className="tiktok" />
              </a>
            </li>
          </ul>

          <div className="hamburger-menu" >
            <a href="#" onClick={() => setShowMediaIcons(!showMediaIcons)}>
              <GiHamburgerMenu />
            </a>
          </div>
        </div>
      </nav>
      <section className="hero-section"></section>
    </>
  );
}

export default Header;
