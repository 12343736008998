import React from 'react'
import Hero from '../components/Hero'
import About from '../components/About'
import Services from '../components/Services'
import Works from '../components/Works'
import { Link } from 'react-router-dom'

function HomePage() {
  return (
      <>
      <Hero />
      <About />
      <h1 className='kdmaat'>من خدماتنا</h1>
      <Services />
      <h1 className='kdmaat'> من مشاريعنا </h1>
      <Works />
      <div className='button-more'>
         <Link  to='/projects'   >
          <button> المزيد</button>
          </Link> 
              </div>
      </>
  )
}

export default HomePage
