import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';
import '../components/style/contactinfoitems.css'


const ContactForm = ()=> {
   





    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
          .sendForm('service_e67xv9v', 'template_odi3brw', form.current, {
            publicKey: 'P18ESwqM1nMBm3tMl',
          })
          .then(
            () => {
              console.log('SUCCESS!');
            },
            (error) => {
              console.log('FAILED...', error.text);
            },
        );
        e.target.reset();
    };
   
    
    return (
      <section>
            <div className='form-group'>
                <h2 className='text-contact'> تواصل معنا عبر البريد الألكتروني</h2>
                <form ref={form} onSubmit={sendEmail}
                    className='form-style'>
                    <div className='form-group'>
                    <input type='text'
                        placeholder='الاسم'
                        name='user-name' required />
                    </div>
                    <div className='form-group'>

                    <input type='email'
                        placeholder='بريدك الألكتروني'
                            name='user-email' required />
                        </div>
                    <div className='form-group'>

                    <input type='text'
                        placeholder='الموضوع'
                            name='subject' required />
                        </div>
                    
                    <textarea  placeholder="الملاحظات" name="message" cols="30" rows="10"></textarea>
                    <button className="button-contact" type='submit' >ارسال </button>
                    


              {/* <div className="form-group">
                  <label htmlFor='name'>الأسم: </label>
                  <input type="text" id='name'
                      name='name'
                      value={name}
                  onChange={e =>setName(e.target.value)}/>
              </div>

               <div className="form-group">
                  <label htmlFor='phone' required>رقم هاتفك :</label>
                  <input type="phone" id='phone'
                      name='phone'
                      value={phone}
                  onChange={e =>setPhone(e.target.value)}/>
              </div>

              <div className="form-group">
                  <label htmlFor='message'>
                      استفسارك :
                      <textarea  
                          type="text"
                          id='message'
                          message="message"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                      />
                  </label>
                
              </div>
              <button className='button-contact' type='submit'>ارسال</button> */}
      </form>
    </div>
        </section>
  )
}

export default ContactForm
