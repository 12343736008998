import React, { useState } from 'react'
import '../components/style/gallery.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { } from '@fortawesome/free-solid-svg-icons'
import { faCircleArrowLeft,faCircleArrowRight,faCircleXmark } from '@fortawesome/free-solid-svg-icons'

function Gallary() {
    const [slideNumber, setSlideMumber] = useState(0)
    const [openModel, setOpenModel] = useState(false)
    const hanleOpenModel = (index) => {
        setSlideMumber(index)
        setOpenModel(true)
        
    }
    // close modal
    const handelCloseModel = () => {
        setOpenModel(false)
        
    }
    //previous image
    const prevSlide = () => {
        slideNumber === 0 ? setSlideMumber(galleryImages.length - 1)
            : setSlideMumber(slideNumber - 1)
        
    }
    //next image
    const nextSlide = () => {
        slideNumber + 1 === galleryImages.length ? setSlideMumber(0)
            :setSlideMumber(slideNumber+1)

        
    }
    
    const galleryImages = [
   
        {
            img:'images/stff1.jpg'
        },
        {
            img: 'images/stff2.jpg'
        },
        {
            img: 'images/stff3.jpg'
        },
        {
            img: 'images/stff4.jpg'
        },
       
        {
            img: 'images/stf124.jpg'
        },
        {
            img: 'images/stf1114.jpg'
        },
        {
            img: 'images/stf1117.jpg'
        },
        {
            img: 'images/stf1116.jpg'
        },
        {
            img: 'images/stf1118.jpg'
        },
        {
            img: 'images/stf1119.jpg'
        },
        
        
        
        
        
        {
            img: 'images/stff6.jpg'
        },
        {
            img: 'images/stff9.jpg'
        },
        {
            img: 'images/stff44.jpg'
        },
        {
            img: 'images/stf1129.jpg'
        },
        {
            img: 'images/stff5.jpg'
        },
        {
            img: 'images/stf100.jpg'
        },
        {
            img: 'images/stf1101.jpg'
        },
        {
            img: 'images/stf102.jpg'
        },
        {
            img: 'images/stf104.jpg'
        },
        {
            img: 'images/stf105.jpg'
        },
        {
            img: 'images/stf106.jpg'
        },
        {
            img: 'images/stf107.jpg'
        },
        {
            img: 'images/stf108.jpg'
        },
        {
            img: 'images/stf109.jpg'
        },
        {
            img: 'images/stf110.jpg'
        },
        {
            img: 'images/stf1111.jpg'
        },
        
       
        {
            img: 'images/stf1120.jpg'
        },
        {
            img: 'images/stf1121.jpg'
        },
        
       
        {
            img: 'images/stf1123.jpg'
        },
        {
            img: 'images/stf1124.jpg'
        },
        {
            img: 'images/stf1125.jpg'
        },
        {
            img: 'images/stf1126.jpg'
        },
        {
            img: 'images/stf1127.jpg'
        },
        {
            img: 'images/stf1128.jpg'
        },
        {
            img: 'images/stf1129.jpg'
              },
    ]

    return (
        <div>
            {openModel &&
                <div className='sliderwrap'>
                    <FontAwesomeIcon icon={faCircleXmark} className='btnClose' onClick={handelCloseModel} />
                    <FontAwesomeIcon icon={faCircleArrowLeft} className='btnPrev' onClick={prevSlide}/>
                    <FontAwesomeIcon icon={faCircleArrowRight} className='btnNext' onClick={nextSlide} />
                    <div className="fullScreenImg">
                        <img src={galleryImages[slideNumber].img} alt=''></img>

                    </div>

                  

                </div>
            }
            {/* <be />
            current slide number:{slideNumber}
            <br />
            total slides:{galleryImages.length}
            <br/> <br/> */}
          <div className="gallerywrap">
              {
                  galleryImages && galleryImages.map((slide, index) => {
                      return (
                          <div className="single"
                              key={index}
                          onClick={()=>hanleOpenModel(index)}>
                              <img src={slide.img} alt=''></img>
                          </div>
                      )
                  })
              }
          </div>
      
    </div>
  )
}

export default Gallary
