import React from 'react'
import ServicesItems from '../components/ServicesItems'


function ServicesPage() {
  return (
    <>
      <h1 className='kdmaat'>خدماتنا</h1>
      <ServicesItems />
      
      </>
  )
}

export default ServicesPage
